import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import './QRCodeComponent.css';
import { trackEvent } from './ga';


const QRCodeComponent = () => {
  const [activeTab, setActiveTab] = useState('text');
  const [text, setText] = useState('');
  const [vCardData, setVCardData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    organization: '',
    title: '',
  });
  const [vCardQRCode, setVCardQRCode] = useState('');
  const [url, setUrl] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [whatsappMessage, setWhatsappMessage] = useState('');
  const [whatsappQRCode, setWhatsappQRCode] = useState('');

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleVCardInputChange = (event) => {
    const { name, value } = event.target;
    setVCardData({ ...vCardData, [name]: value });
  };

  const handleWhatsappNumberChange = (event) => {
    setWhatsappNumber(event.target.value);
  };

  const handleWhatsappMessageChange = (event) => {
    setWhatsappMessage(event.target.value);
  };

  const generateVCardString = () => {
    const { firstName, lastName, phone, email, address, organization, title } = vCardData;
    return `BEGIN:VCARD
VERSION:3.0
FN:${firstName} ${lastName}
ORG:${organization}
TITLE:${title}
TEL;TYPE=WORK,VOICE:${phone}
ADR;TYPE=WORK:;;${address}
EMAIL:${email}
END:VCARD`;
  };

  const generateVCardQRCode = () => {
    setVCardQRCode(generateVCardString());
    trackEvent('QR Generation', 'Generate vCard QR', 'vCard QR generated');
  };

  const generateWhatsAppQRCode = () => {
    setWhatsappQRCode(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`);
    trackEvent('QR Generation', 'Generate WhatsApp QR', 'WhatsApp QR generated');
  };

  const logoSrc = 'logo.png'; // Replace with the path to your logo image

  return (

    <div>



<nav class="bg-white border-gray-200 dark:bg-gray-900 border-b-2 border-gray-100">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
    <a href="https://flowbite.com/" class="flex items-center space-x-3 rtl:space-x-reverse">
        {/* <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Flowbite Logo" /> */}
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">HDQRCODE</span>
    </a>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
       
        {/* <li>
          <a href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
        </li> */}
      </ul>
    </div>
  </div>
</nav>


<div class="main ">
    <div className="qr-code-container ">
      <h1 className="title">QR Code Generator</h1>
      <div className="tabs">
        <button className={`tab ${activeTab === 'text' ? 'active' : ''}`} onClick={() => setActiveTab('text')}>Text</button>
        <button className={`tab ${activeTab === 'url' ? 'active' : ''}`} onClick={() => setActiveTab('url')}>URL</button>
        <button className={`tab ${activeTab === 'vcard' ? 'active' : ''}`} onClick={() => setActiveTab('vcard')}>vCard</button>
        <button className={`tab ${activeTab === 'whatsapp' ? 'active' : ''}`} onClick={() => setActiveTab('whatsapp')}>WhatsApp</button>
      </div>

      {activeTab === 'text' && (
        <div>
          <input 
            type="text" 
            value={text} 
            onChange={handleInputChange} 
            placeholder="Enter text" 
            className="input-field"
          />
          
        </div>
      )}

      {activeTab === 'vcard' && (
        <div>
          <input
            type="text"
            name="firstName"
            value={vCardData.firstName}
            onChange={handleVCardInputChange}
            placeholder="First Name"
            className="input-field"
          />
          <input
            type="text"
            name="lastName"
            value={vCardData.lastName}
            onChange={handleVCardInputChange}
            placeholder="Last Name"
            className="input-field"
          />
          <input
            type="text"
            name="phone"
            value={vCardData.phone}
            onChange={handleVCardInputChange}
            placeholder="Phone"
            className="input-field"
          />
          <input
            type="email"
            name="email"
            value={vCardData.email}
            onChange={handleVCardInputChange}
            placeholder="Email"
            className="input-field"
          />
          <input
            type="text"
            name="address"
            value={vCardData.address}
            onChange={handleVCardInputChange}
            placeholder="Address"
            className="input-field"
          />
          <input
            type="text"
            name="organization"
            value={vCardData.organization}
            onChange={handleVCardInputChange}
            placeholder="Organization"
            className="input-field"
          />
          <input
            type="text"
            name="title"
            value={vCardData.title}
            onChange={handleVCardInputChange}
            placeholder="Title"
            className="input-field"
          />
          <button className="generate-btn" onClick={generateVCardQRCode}>Generate QR</button>
          
        </div>
      )}

      {activeTab === 'url' && (
        <div>
          <input
            type="text"
            value={url}
            onChange={handleUrlChange}
            placeholder="Enter URL"
            className="input-field"
          />
          
        </div>
      )}

      {activeTab === 'whatsapp' && (
        <div>
          <input
            type="text"
            value={whatsappNumber}
            onChange={handleWhatsappNumberChange}
            placeholder="Enter mobile number"
            className="input-field"
          />
          <input
            type="text"
            value={whatsappMessage}
            onChange={handleWhatsappMessageChange}
            placeholder="Enter WhatsApp message"
            className="input-field"
          />
          <button className="generate-btn" onClick={generateWhatsAppQRCode}>Generate QR</button>
          
        </div>
      )}

          

    </div>

         <div class="qr-code-seperate  ">

                {activeTab === 'text' && text && (
                        <div className="qr-code">
                          <QRCode value={text} size={256} />
                          {/* <img
                            src={logoSrc}
                            alt="Logo"
                            className="qr-code-logo"
                          /> */}
                        </div>
                      )}


                {activeTab === 'url' && url && (
                    <div className="qr-code">
                      <QRCode value={url} size={256} />
                      {/* <img
                        src={logoSrc}
                        alt="Logo"
                        className="qr-code-logo"
                      /> */}
                    </div>
                  )}

            {activeTab === 'whatsapp' && whatsappQRCode && (
                      <div className="qr-code">
                        <QRCode value={whatsappQRCode} size={256} />
                        {/* <img
                          src={logoSrc}
                          alt="Logo"
                          className="qr-code-logo"
                        /> */}
                      </div>
                    )}



          {activeTab === 'vcard' &&  vCardQRCode && (
            <div className="qr-code">
              <QRCode value={vCardQRCode} size={256} />
              {/* <img
                src={logoSrc}
                alt="Logo"
                className="qr-code-logo"
              /> */}
            </div>
          )}
          </div>

    </div>
    </div>
  );
};

export default QRCodeComponent;
